export const environment = {
  production: false,
  api_endpoint: "https://dev.th.api-dashboard.aiqsrorders.com/",
  be_endpoint: "https://dev.th.business-engine.aiqsrorders.com",
  api_endpoint_admin: "https://dev.admin.api-dashboard.aiqsrorders.com/",
  // be_endpoint: "http://localhost:3006",
  firebaseConfig: {
    apiKey: "AIzaSyBZbDeXP4q8Oc5jpBEh6LTdhU5_uOD1TMs",
    authDomain: "dev.agent.voix.ai",
    databaseURL: "https://nonprod-agent-notifications.firebaseio.com",
    projectId: "nonprod-agent-notifications",
    storageBucket: "nonprod-agent-notifications.appspot.com",
    messagingSenderId: "1031623981835",
  },
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InZwc0B2b2l4LmFpIiwiaWF0IjoxNTI1MjQwMDA3fQ.ME1-ipxpiisqqR6ZUTqaUrMGw5DpIB3hS0qTKCqWhXw",
  ws: "wss://qa.hub.callezra.com/nexmosocket/",
  io_url: "https://dev.agent-backend.callezra.com/",
  api_key : "eeaf271160bf4264b9a818f4d72f95e0",
  app_token : "ahRzfm11c3RlcmktaGl6bWV0bGVyaXIfCxISVGVuYW50QXBwbGljYXRpb25zGICAxPeEtd4JDKIBG3RjLWJwby5jYWxsY2VudGVyc3R1ZGlvLmNvbQ",
  role: {
    admin: [
      "gurpreet@voix.ai",
      "shashank@voix.ai",
      "rajesh.pelne@voix.ai",
      "vai@voix.ai",
    ],
  },
  admin: {
    url: 'https://dev.admin.agent-dashboard.aiqsrorders.com',
  },
  ssoSubDomain: 'agent-dashboard.aiqsrorders.com',
  isAdminBuild: false,
  flagsmith_environment_id: "jYfpM5yVvLkZceb4M8gYCk",
  agent_dashboard_key: "a790ad10b4696ffd78e1",
  agent_backend_api_url: "https://dev.agent-backend.callezra.com/"
};

